<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        Satıcı Bilgileri
      </div>
    </template>

    <div class="row">
      <!-- ünvan -->
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="unvan">Ünvanı * </label>
          <b-form-input type="text" id="unvan" required />
        </b-form-group>
      </div>
      <!-- kısa ünvan -->
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="kunvan">Kısa Ünvanı * </label>
          <b-form-input type="text" id="kunvan" required />
        </b-form-group>
      </div>
      <!-- sahibi -->
      <div class="col-12">
        <div class="form-group">
          <label for="saticiTip">Satıcı Tipi * </label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option selected>Seçiniz...</option>
            <option>Banka</option>
            <option>Kurumsal</option>
            <option>Bireysel</option>
          </select>
        </div>
        <b-form-checkbox
          name="check-button"
          switch
          class="mb-5"
        >
          Satıcı Durumu
        </b-form-checkbox>
      </div>
    </div>
    <hr w-100 />

    <div class="row mt-5">
      <div class="col-12">
        <div class="card-sub-header mb-5">İletişim Bilgileri</div>
      </div>
      <!-- ünvan -->
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="telNo2">Telefon 1 </label>
          <b-form-input type="phone" id="telNo2" required />
        </b-form-group>
      </div>
      <!-- tel -->
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="tel2">Telefon 2</label>
          <b-form-input type="phone" id="tel2" required />
        </b-form-group>
      </div>
      <!-- faks -->
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="faks">Faks</label>
          <b-form-input type="text" id="faks" required />
        </b-form-group>
      </div>
      <!-- vergi d -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="vergiD">Vergi Dairesi</label>
          <b-form-input type="text" id="vergiD" required />
        </b-form-group>
      </div>
      <!-- vergi no -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="VergiN">Vergi Numarası</label>
          <b-form-input type="phone" id="VergiN" required />
        </b-form-group>
      </div>
      <!-- hizmet bedeli -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="VergiN">Hizmet Bedeli Hesap Numarası (IBAN)</label>
          <b-form-input
            type="phone"
            value="TR53 0006 4000 0015 3530 0403 19"
            id="VergiN"
            required
          />
        </b-form-group>
      </div>
      <!-- hesap no -->
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="VergiN">Hesap Numarası (IBAN)</label>
          <b-form-input
            type="phone"
            value="TR27 0005 9024 7057 8024 7527 32"
            id="VergiN"
            required
          />
        </b-form-group>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <div class="card-sub-header mb-5">Şirket Ayarları</div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="hizmetTip">Hizmet Bedeli Tipi * </label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option selected>Seçiniz...</option>
            <option>Müşteri</option>
            <option>Bankadan</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="hizmetTip">İhale Süresi Tipi * </label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option selected>Seçiniz...</option>
            <option>Normal</option>
            <option>İş Günü</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <b-form-group class="add-style">
          <label for="ihaleSue">İhale Süresi (Gün)</label>
          <b-form-input type="text" id="ihaleSure" required />
        </b-form-group>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="hizmetTip">Teminat Tipi</label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option selected>Seçiniz...</option>
            <option>Başlangıç Bedeli Üzerinden</option>
            <option>İhale Sonuç Bedeli Üzerinden</option>
          </select>
        </div>
      </div>
    </div>
    <hr w-100 />

    <div class="row mt-5" v-for="(i) in malikCount" :id="'malik-' + i" :key="'a' +i" >
      <div class="col-12">
        <div class="card-sub-header mb-5">Teminat Oranı (%)</div>
      </div>
      <div class="col-1">
        <b-button variant="light" @click="deleteRow(i)">
          <i class="flaticon2-trash p-0"></i>
        </b-button>
      </div>
      <div class="col">
        <div class="form-group">
          <select class="form-control" id="exampleFormControlSelect1">
            <option selected>Seçiniz...</option>
            <option>Tümü</option>
            <option>0- 5.000.000 ₺</option>
            <option>5.000.000 -10.000.000₺</option>
            <option>10.000.000 +</option>
          </select>
        </div>
      </div>
      <div class="col" >
        <b-form-group class="add-style">
          <b-form-input type="text" id="no" value=" 3" required />
        </b-form-group>
      </div>
      </div>
      <div class="col-12 mt-5">
        <button class="p-3 bg-light rounded-sm" @click="malikCount++">
          <b-icon icon="plus" aria-hidden="true" ></b-icon> Ekle
        </button>
      </div>
      <div class="col-12 mt-5">
        <b-form-checkbox
          name="check-button"
          switch
          class="mb-5"
        >
          Hizmet bedelini sıralı hesaplayıp topla
        </b-form-checkbox>
      </div>

    <div class="row mt-5" v-for="(i) in malikCount1" :id="'malik1-' + i" :key="'b'+ i">
      <div class="col-12">
        <div class="card-sub-header mb-5">Hizmet Bedeli (%)</div>
      </div>
      <div class="col-1">
        <button class="p-3 bg-light rounded-sm" @click="deleteMalik1(i)">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </button>
      </div>
      <div class="col">
        <div class="form-group">
          <select class="form-control" id="exampleFormControlSelect1">
            <option selected>Yok</option>
            <option>1.000.000.000</option>
            <option>2.000.000.000</option>
            <option>3.000.000.000</option>
          </select>
        </div>
      </div>
      <div class="col">
        <b-form-group class="add-style">
          <b-form-input type="text" id="no" value="4" required />
        </b-form-group>
      </div>
      
      
    </div>
    <div class="col-12 mt-5">
        <button class="p-3 bg-light rounded-sm" @click="malikCount1++">
          <b-icon icon="plus" aria-hidden="true" ></b-icon> Ekle
        </button>
      </div>
    <hr w-100 />

    <div class="row mt-5" v-for="(i) in malikCount2" :id="'malik2-' + i" :key="i">
      <div class="col-12">
        <div class="card-sub-header mb-5">Hizmet Bedeli Limit Üzeri (%)</div>
      </div>

      <div class="col-1">
        <button class="p-3 bg-light rounded-sm" @click="deleteMalik2(i)">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </button>
      </div>
      <div class="col">
        <div class="form-group">
          <select class="form-control" id="exampleFormControlSelect1">
            <option selected>Yok</option>
            <option>1.000.000.000</option>
            <option>2.000.000.000</option>
            <option>3.000.000.000</option>
          </select>
        </div>
      </div>
      <div class="col">
        <b-form-group class="add-style">
          <b-form-input type="text" id="no" value="Yüzde" required />
        </b-form-group>
      </div>
    
    </div>
    <div class="col-12 mt-5">
      <button class="p-3 bg-light rounded-sm" @click="malikCount2++">
        <b-icon icon="plus" aria-hidden="true"></b-icon> Ekle
      </button>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <div class="form-group">
          <label for="yuzde">Yüzde Artış Birimi * </label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option value="" selected>Seçiniz</option>
            <option>Yüzde (%)</option>
            <option>Türk Lirası (TL)</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <b-form-group class="add-style">
          <label for="min">Minimum Artış Değeri *</label>
          <b-form-input type="text" id="min" required />
        </b-form-group>
      </div>
    </div>
    <hr w-100 />

    <h4 class="mt-5 card-sub-header mb-5">Satıcı Logosu (Renkli)</h4>
    <b-form-group class="mb-0 mt-5">
      <b-form-file
        id="file"
        class="rounded-sm"
        placeholder="Dosya seçiniz"
        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
      ></b-form-file>
    </b-form-group>

    <div class="row">
      <div class="col-12 ml-5 mt-5">
        <ul>
          <li>
            %%customer_adi_soyadi%% - Giriş yapmış kullanıcının adı soyadı
          </li>
          <li>%%customer_adres%% - Giriş yapmış kullanıcının adresi</li>
          <li>
            %%customer_telefon%% - Giriş yapmış kullanıcının telefon numarası
          </li>
          <li>%%customer_email%% - Giriş yapmış kullanıcının e-mail adresi</li>
          <li>
            %%suan_tarih%% - Sözleşme indir butonuna başıldığı anın tarihi
          </li>
          <li>%%suan_saat%% - Sözleşme indir butonuna başıldığı anın saati</li>
          <li>%%hizmet_bedeli%% - Sözleşmeye ait ihalenin hizmet bedeli</li>
          <li>
            %%hizmetbedeli_iban%% - Bankaya ait, hizmet bedelinin yatırılması
            gereken iban adresi.
          </li>
        </ul>
      </div>
    </div>
    <h4 class="mt-5 card-sub-header mb-4">Satış Sözleşmesi</h4>
    <b-form-group class="mb-0 mt-5">
      <a href="#">İndir</a>
      <b-form-file
        id="file"
        class="rounded-sm mb-5"
        placeholder="Dosya seçiniz"
        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
      ></b-form-file>
    </b-form-group>

    <h4 class="mt-5 card-sub-header">Mesafeli Satış Sözleşmesi</h4>
    <b-form-group class="mb-0 mt-5">
      <a href="#">İndir</a>
      <b-form-file
        id="file"
        class="rounded-sm mb-5"
        placeholder="Dosya seçiniz"
        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
      ></b-form-file>
    </b-form-group>

    <div class="d-flex">
      <button class="bg-light rounded-sm p-3 mx-auto mt-5">
        Satıcı Ayarlarını İlanlara Uygula
      </button>
      <button class="saveButton rounded-sm p-3 mx-auto mt-5">Kaydet</button>
    </div>
  </Card>
</template>

<script>
export default {
  data() {
    return {
      row: [],
      districts: [],
      counties: [
        {
          il: "Adana",
          plaka: 1,
          ilceleri: [
            "Aladağ",
            "Ceyhan",
            "Çukurova",
            "Feke",
            "İmamoğlu",
            "Karaisalı",
            "Karataş",
            "Kozan",
            "Pozantı",
            "Saimbeyli",
            "Sarıçam",
            "Seyhan",
            "Tufanbeyli",
            "Yumurtalık",
            "Yüreğir",
          ],
        },
        {
          il: "Adıyaman",
          plaka: 2,
          ilceleri: [
            "Besni",
            "Çelikhan",
            "Gerger",
            "Gölbaşı",
            "Kahta",
            "Merkez",
            "Samsat",
            "Sincik",
            "Tut",
          ],
        },
        {
          il: "Afyonkarahisar",
          plaka: 3,
          ilceleri: [
            "Başmakçı",
            "Bayat",
            "Bolvadin",
            "Çay",
            "Çobanlar",
            "Dazkırı",
            "Dinar",
            "Emirdağ",
            "Evciler",
            "Hocalar",
            "İhsaniye",
            "İscehisar",
            "Kızılören",
            "Merkez",
            "Sandıklı",
            "Sinanpaşa",
            "Sultandağı",
            "Şuhut",
          ],
        },
        {
          il: "Ağrı",
          plaka: 4,
          ilceleri: [
            "Diyadin",
            "Doğubayazıt",
            "Eleşkirt",
            "Hamur",
            "Merkez",
            "Patnos",
            "Taşlıçay",
            "Tutak",
          ],
        },
        {
          il: "Amasya",
          plaka: 5,
          ilceleri: [
            "Göynücek",
            "Gümüşhacıköy",
            "Hamamözü",
            "Merkez",
            "Merzifon",
            "Suluova",
            "Taşova",
          ],
        },
        {
          il: "Ankara",
          plaka: 6,
          ilceleri: [
            "Altındağ",
            "Ayaş",
            "Bala",
            "Beypazarı",
            "Çamlıdere",
            "Çankaya",
            "Çubuk",
            "Elmadağ",
            "Güdül",
            "Haymana",
            "Kalecik",
            "Kızılcahamam",
            "Nallıhan",
            "Polatlı",
            "Şereflikoçhisar",
            "Yenimahalle",
            "Gölbaşı",
            "Keçiören",
            "Mamak",
            "Sincan",
            "Kazan",
            "Akyurt",
            "Etimesgut",
            "Evren",
            "Pursaklar",
          ],
        },
        {
          il: "Antalya",
          plaka: 7,
          ilceleri: [
            "Akseki",
            "Alanya",
            "Elmalı",
            "Finike",
            "Gazipaşa",
            "Gündoğmuş",
            "Kaş",
            "Korkuteli",
            "Kumluca",
            "Manavgat",
            "Serik",
            "Demre",
            "İbradı",
            "Kemer",
            "Aksu",
            "Döşemealtı",
            "Kepez",
            "Konyaaltı",
            "Muratpaşa",
          ],
        },
        {
          il: "Artvin",
          plaka: 8,
          ilceleri: [
            "Ardanuç",
            "Arhavi",
            "Merkez",
            "Borçka",
            "Hopa",
            "Şavşat",
            "Yusufeli",
            "Murgul",
          ],
        },
        {
          il: "Aydın",
          plaka: 9,
          ilceleri: [
            "Merkez",
            "Bozdoğan",
            "Efeler",
            "Çine",
            "Germencik",
            "Karacasu",
            "Koçarlı",
            "Kuşadası",
            "Kuyucak",
            "Nazilli",
            "Söke",
            "Sultanhisar",
            "Yenipazar",
            "Buharkent",
            "İncirliova",
            "Karpuzlu",
            "Köşk",
            "Didim",
          ],
        },
        {
          il: "Balıkesir",
          plaka: 10,
          ilceleri: [
            "Altıeylül",
            "Ayvalık",
            "Merkez",
            "Balya",
            "Bandırma",
            "Bigadiç",
            "Burhaniye",
            "Dursunbey",
            "Edremit",
            "Erdek",
            "Gönen",
            "Havran",
            "İvrindi",
            "Karesi",
            "Kepsut",
            "Manyas",
            "Savaştepe",
            "Sındırgı",
            "Gömeç",
            "Susurluk",
            "Marmara",
          ],
        },
        {
          il: "Bilecik",
          plaka: 11,
          ilceleri: [
            "Merkez",
            "Bozüyük",
            "Gölpazarı",
            "Osmaneli",
            "Pazaryeri",
            "Söğüt",
            "Yenipazar",
            "İnhisar",
          ],
        },
        {
          il: "Bingöl",
          plaka: 12,
          ilceleri: [
            "Merkez",
            "Genç",
            "Karlıova",
            "Kiğı",
            "Solhan",
            "Adaklı",
            "Yayladere",
            "Yedisu",
          ],
        },
        {
          il: "Bitlis",
          plaka: 13,
          ilceleri: [
            "Adilcevaz",
            "Ahlat",
            "Merkez",
            "Hizan",
            "Mutki",
            "Tatvan",
            "Güroymak",
          ],
        },
        {
          il: "Bolu",
          plaka: 14,
          ilceleri: [
            "Merkez",
            "Gerede",
            "Göynük",
            "Kıbrıscık",
            "Mengen",
            "Mudurnu",
            "Seben",
            "Dörtdivan",
            "Yeniçağa",
          ],
        },
        {
          il: "Burdur",
          plaka: 15,
          ilceleri: [
            "Ağlasun",
            "Bucak",
            "Merkez",
            "Gölhisar",
            "Tefenni",
            "Yeşilova",
            "Karamanlı",
            "Kemer",
            "Altınyayla",
            "Çavdır",
            "Çeltikçi",
          ],
        },
        {
          il: "Bursa",
          plaka: 16,
          ilceleri: [
            "Gemlik",
            "İnegöl",
            "İznik",
            "Karacabey",
            "Keles",
            "Mudanya",
            "Mustafakemalpaşa",
            "Orhaneli",
            "Orhangazi",
            "Yenişehir",
            "Büyükorhan",
            "Harmancık",
            "Nilüfer",
            "Osmangazi",
            "Yıldırım",
            "Gürsu",
            "Kestel",
          ],
        },
        {
          il: "Çanakkale",
          plaka: 17,
          ilceleri: [
            "Ayvacık",
            "Bayramiç",
            "Biga",
            "Bozcaada",
            "Çan",
            "Merkez",
            "Eceabat",
            "Ezine",
            "Gelibolu",
            "Gökçeada",
            "Lapseki",
            "Yenice",
          ],
        },
        {
          il: "Çankırı",
          plaka: 18,
          ilceleri: [
            "Merkez",
            "Çerkeş",
            "Eldivan",
            "Ilgaz",
            "Kurşunlu",
            "Orta",
            "Şabanözü",
            "Yapraklı",
            "Atkaracalar",
            "Kızılırmak",
            "Bayramören",
            "Korgun",
          ],
        },
        {
          il: "Çorum",
          plaka: 19,
          ilceleri: [
            "Alaca",
            "Bayat",
            "Merkez",
            "İskilip",
            "Kargı",
            "Mecitözü",
            "Ortaköy",
            "Osmancık",
            "Sungurlu",
            "Boğazkale",
            "Uğurludağ",
            "Dodurga",
            "Laçin",
            "Oğuzlar",
          ],
        },
        {
          il: "Denizli",
          plaka: 20,
          ilceleri: [
            "Acıpayam",
            "Buldan",
            "Çal",
            "Çameli",
            "Çardak",
            "Çivril",
            "Merkez",
            "Merkezefendi",
            "Pamukkale",
            "Güney",
            "Kale",
            "Sarayköy",
            "Tavas",
            "Babadağ",
            "Bekilli",
            "Honaz",
            "Serinhisar",
            "Baklan",
            "Beyağaç",
            "Bozkurt",
          ],
        },
        {
          il: "Diyarbakır",
          plaka: 21,
          ilceleri: [
            "Kocaköy",
            "Çermik",
            "Çınar",
            "Çüngüş",
            "Dicle",
            "Ergani",
            "Hani",
            "Hazro",
            "Kulp",
            "Lice",
            "Silvan",
            "Eğil",
            "Bağlar",
            "Kayapınar",
            "Sur",
            "Yenişehir",
            "Bismil",
          ],
        },
        {
          il: "Edirne",
          plaka: 22,
          ilceleri: [
            "Merkez",
            "Enez",
            "Havsa",
            "İpsala",
            "Keşan",
            "Lalapaşa",
            "Meriç",
            "Uzunköprü",
            "Süloğlu",
          ],
        },
        {
          il: "Elazığ",
          plaka: 23,
          ilceleri: [
            "Ağın",
            "Baskil",
            "Merkez",
            "Karakoçan",
            "Keban",
            "Maden",
            "Palu",
            "Sivrice",
            "Arıcak",
            "Kovancılar",
            "Alacakaya",
          ],
        },
        {
          il: "Erzincan",
          plaka: 24,
          ilceleri: [
            "Çayırlı",
            "Merkez",
            "İliç",
            "Kemah",
            "Kemaliye",
            "Refahiye",
            "Tercan",
            "Üzümlü",
            "Otlukbeli",
          ],
        },
        {
          il: "Erzurum",
          plaka: 25,
          ilceleri: [
            "Aşkale",
            "Çat",
            "Hınıs",
            "Horasan",
            "İspir",
            "Karayazı",
            "Narman",
            "Oltu",
            "Olur",
            "Pasinler",
            "Şenkaya",
            "Tekman",
            "Tortum",
            "Karaçoban",
            "Uzundere",
            "Pazaryolu",
            "Köprüköy",
            "Palandöken",
            "Yakutiye",
            "Aziziye",
          ],
        },
      ],
      rows: [{}],
      malikCount: 1,
      malikCount1: 1,
      malikCount2:1,
    };
  },
  methods: {
    countyChange(e) {
      let filteredData = this.counties.filter(
        (county) => county.plaka == e.target.value
      );
      this.districts = filteredData[0].ilceleri;
    },
    addRow() {
      console.log(document.querySelector("#roww"));
    },
    malikEkle() {
      this.malikCount += 1;
    },
      deleteRow(i) {
      let row = document.getElementById("malik-"+i)
      row.remove()
      
    },
    deleteMalik1(i){
      let row = document.getElementById("malik1-"+i)
      row.remove()
    },
    deleteMalik2(i){
      let row = document.getElementById("malik2-"+i)
      row.remove()
    }
  },
};
</script>


